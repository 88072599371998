export const zoom = (element: HTMLElement, zoom = 1.5) => {
  if (!element) {
    return
  }

  element.addEventListener('mouseover', () => (element.style.transform = `scale(${zoom}`))
  element.addEventListener('mouseout', () => (element.style.transform = null))

  element.addEventListener('mousemove', event => {
    const pageX = event.pageX
    const pageY = event.pageY
    const elementPosition = element.getBoundingClientRect()
    const { height, width, left, top } = elementPosition

    const leftPosition = pageX - left
    const topPosition = pageY - top

    element.style.transformOrigin = `${(leftPosition / width) * 100}% ${(topPosition / height) * 100}%`
  })
}
