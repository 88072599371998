import pageTracker from '@components/page-tracker/page-tracker'
import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { ContactHubContext } from '../ContactHubContext'
import { getDataByKey } from '../helper'

export type DrilldownItemType = {
  title: string
  link?: string
  supportType?: string
}

type ContactHubDrilldownItemType = {
  item: DrilldownItemType
}

export default function ContactHubDrilldownItem({ item }: ContactHubDrilldownItemType) {
  const { originalData, setCurrentData, setSelectedItem, setFadeDirection } = useContext(ContactHubContext)

  function handleOnClick(event) {
    setFadeDirection('slide-fade-next')
    event.preventDefault()
    const retrievedData = getDataByKey(originalData, item.title)
    setCurrentData(retrievedData)
    setSelectedItem(item.title)
  }

  function setSupportType() {
    pageTracker.setSupportType(item.supportType)
  }

  return (
    <div className="cmp-contacthub__drilldown-item" {...(item.link ? {} : { role: 'button', tabIndex: 0, onClick: handleOnClick })}>
      <a
        href={item.link}
        className={cls({
          'cmp-contacthub__drilldown-item--text': true,
          'cmp-contacthub__drilldown-item--directlink': item.link,
        })}
        data-support-type={item.supportType}
        onClick={setSupportType}
      >
        {item.title}
        <span className="cmp-contachub__drilldown-item-icon icon-keyboard_arrow_right" />
      </a>
    </div>
  )
}
