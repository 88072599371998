export const pageTracker = {
  previousInquiryTag: function () {
    return this.get()[this.isSkippedPage() ? 'inquiryTag' : 'previousInquiryTag']
  },
  previousIndustryTag: function () {
    return this.get()[this.isSkippedPage() ? 'industryTag' : 'previousIndustryTag']
  },
  previousUrl: function () {
    return this.get()[this.isSkippedPage() ? 'currentUrl' : 'previousUrl']
  },
  supportType: function () {
    return this.get()['supportType']
  },
  updateState: function () {
    if (this.isSkippedPage()) {
      return
    }
    const pageTracker = this.get()
    const pageUrl = window.location.href

    if (pageTracker.currentUrl !== pageUrl) {
      const pageInquiryTag = document.head.querySelector('[name~=inquiry-tag][content]')?.content || ''
      const pageIndustryTag = document.head.querySelector('[name~=industry-tag][content]')?.content || ''

      pageTracker.previousInquiryTag = pageTracker.inquiryTag
      pageTracker.previousIndustryTag = pageTracker.industryTag
      pageTracker.previousUrl = pageTracker.currentUrl

      pageTracker.inquiryTag = pageInquiryTag
      pageTracker.industryTag = pageIndustryTag
      pageTracker.currentUrl = pageUrl
      pageTracker.supportType = this.get()['supportType'] || ''
      this.set(pageTracker)
    }
  },
  isSkippedPage: function () {
    return !!document.querySelector('.contacthub') || !!document.querySelector('.overlaypage')
  },
  get: function () {
    try {
      return JSON.parse(sessionStorage.getItem('pageTracker')) || {}
    } catch (e) {
      return {}
    }
  },
  set: function (pageTracker) {
    sessionStorage.setItem('pageTracker', JSON.stringify(pageTracker))
  },
  setSupportType: function (supportType = '') {
    const pageTracker = this.get()
    const updatedPageTrackerObject = { ...pageTracker, supportType }
    sessionStorage.setItem('pageTracker', JSON.stringify(updatedPageTrackerObject))
  },
}

pageTracker.updateState()
export default pageTracker
